body {
    margin: 0 auto;
    padding: 0;
    line-height: 1.6;
    font-size: 18px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;

    font-family: 'Merriweather', serif;

    &[data-section="info"] {
        background-color: #000;
        color: #fff;
        overflow: hidden;

        .box--info {
            cursor: initial;
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
            overflow: hidden;
        }

        .info {
            &__picture {
                opacity: 1;
                transition: 1s;
            }

            &__content {
                height: 100%;
            }

            &__overflow {
                span {
                    transform: translateY(0);
                    transition: 1s;
                }
            }
        }

        .nav__el {
            color: rgba(255, 255, 255, .5);

            &--actif {
                color: rgba(255, 255, 255, 1);
            }

            &:before {
                border-color: #fff;
            }

            &:hover {
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    &[data-section="work"] {
        .box--work {
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
        }
    }

    &[data-section="contact"] {
        .box--contact {
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
        }
    }

    &[data-section="tfa"] {
        overflow: auto;

        .box--tfa {
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
        }

        .project {
            &__overflow {
                span {
                    transform: translateY(0);
                    transition: .5s .5s;
                }
            }

            &__link {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__arrow {
                transform: translateX(0);
                transition: .5s .5s;
            }

            small {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__letter {
                &--1 {
                    transform: translateX(0);
                    transition: .5s;
                }

                &--2 {
                    transform: translateX(0);
                    transition: .5s .05s;
                }

                &--3 {
                    transform: translateX(0);
                    transition: .5s .1s;
                }

                &--4 {
                    transform: translateX(0);
                    transition: .5s .15s;
                }

                &--5 {
                    transform: translateX(0);
                    transition: .5s .2s;
                }

                &--6 {
                    transform: translateX(0);
                    transition: .5s .25s;
                }

                &--7 {
                    transform: translateX(0);
                    transition: .5s .3s;
                }

                &--8 {
                    transform: translateX(0);
                    transition: .5s .35s;
                }

                &--9 {
                    transform: translateX(0);
                    transition: .5s .4s;
                }
            }
        }
    }

    &[data-section="dataplay"] {
        overflow: auto;

        .box--dataplay {
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
        }

        .project {
            &__overflow {
                span {
                    transform: translateY(0);
                    transition: .5s .5s;
                }
            }

            &__link {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__arrow {
                transform: translateX(0);
                transition: .5s .5s;
            }

            small {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__letter {
                &--1 {
                    transform: translateX(0);
                    transition: .5s;
                }

                &--2 {
                    transform: translateX(0);
                    transition: .5s .05s;
                }

                &--3 {
                    transform: translateX(0);
                    transition: .5s .1s;
                }

                &--4 {
                    transform: translateX(0);
                    transition: .5s .15s;
                }

                &--5 {
                    transform: translateX(0);
                    transition: .5s .2s;
                }
            }
        }
    }

    &[data-section="rux"] {
        overflow: auto;

        .box--rux {
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
        }

        .project {
            &__overflow {
                span {
                    transform: translateY(0);
                    transition: .5s .5s;
                }
            }

            &__link {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__arrow {
                transform: translateX(0);
                transition: .5s .5s;
            }

            small {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__letter {
                &--1 {
                    transform: translateX(0);
                    transition: .5s;
                }

                &--2 {
                    transform: translateX(0);
                    transition: .5s .05s;
                }

                &--3 {
                    transform: translateX(0);
                    transition: .5s .1s;
                }

                &--4 {
                    transform: translateX(0);
                    transition: .5s .15s;
                }

                &--5 {
                    transform: translateX(0);
                    transition: .5s .2s;
                }

                &--6 {
                    transform: translateX(0);
                    transition: .5s .25s;
                }

                &--7 {
                    transform: translateX(0);
                    transition: .5s .3s;
                }

                &--8 {
                    transform: translateX(0);
                    transition: .5s .35s;
                }

                &--9 {
                    transform: translateX(0);
                    transition: .5s .4s;
                }
            }
        }
    }

    &[data-section="iolce"] {
        overflow: auto;

        .box--iolce {
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
        }

        .project {
            &__overflow {
                span {
                    transform: translateY(0);
                    transition: .5s .5s;
                }
            }

            &__link {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__arrow {
                transform: translateX(0);
                transition: .5s .5s;
            }

            small {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__letter {
                &--1 {
                    transform: translateX(0);
                    transition: .5s;
                }

                &--2 {
                    transform: translateX(0);
                    transition: .5s .05s;
                }

                &--3 {
                    transform: translateX(0);
                    transition: .5s .1s;
                }

                &--4 {
                    transform: translateX(0);
                    transition: .5s .15s;
                }

                &--5 {
                    transform: translateX(0);
                    transition: .5s .2s;
                }

                &--6 {
                    transform: translateX(0);
                    transition: .5s .25s;
                }
            }
        }
    }

    &[data-section="wishlist"] {
        overflow: auto;

        .box--wishlist {
            opacity: 1;
            pointer-events: all;
            transform: scaleY(1);
        }

        .project {
            &__overflow {
                span {
                    transform: translateY(0);
                    transition: .5s .5s;
                }
            }

            &__link {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__arrow {
                transform: translateX(0);
                transition: .5s .5s;
            }

            small {
                transform: translateY(0);
                transition: .5s .5s;
            }

            &__letter {
                &--1 {
                    transform: translateX(0);
                    transition: .5s;
                }

                &--2 {
                    transform: translateX(0);
                    transition: .5s .05s;
                }

                &--3 {
                    transform: translateX(0);
                    transition: .5s .1s;
                }

                &--4 {
                    transform: translateX(0);
                    transition: .5s .15s;
                }

                &--5 {
                    transform: translateX(0);
                    transition: .5s .2s;
                }

                &--6 {
                    transform: translateX(0);
                    transition: .5s .25s;
                }

                &--7 {
                    transform: translateX(0);
                    transition: .5s .3s;
                }

                &--8 {
                    transform: translateX(0);
                    transition: .5s .35s;
                }
            }
        }
    }

    &[data-loading="true"] {
        overflow: hidden;
    }

    &[data-loaders="black"] {
        .loaders--black {
            transform: scaleX(1);
            opacity: 0;
            transition: transform .5s, opacity 0s 1s;
        }
    }

    &[data-loaders="grey"] {
        .loaders--grey {
            transform: scaleX(1);
            opacity: 0;
            transition: transform .5s, opacity 0s 1s;
        }
    }

    &[data-loaders="white"] {
        .loaders--white {
            transform: scaleX(1);
            opacity: 0;
            transition: transform .5s, opacity .5s 1s;
        }
    }
}

ul {
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0;
}

::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.navigation {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

.nav {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    font-family: 'Roboto', sans-serif;
    font-size: .8em;

    &__el {
        position: fixed;
        margin: 0;
        z-index: 16;
        color: rgba(0, 0, 0, .5);

        cursor: pointer;

        &--work {
            top: 40px;
            left: 80px;
        }

        &--info {
            top: 40px;
            right: 80px;
        }

        &--actif {
            color: rgba(0, 0, 0, 1);
        }

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            border: 1px solid black;
            transition: .5s;
        }

        &:hover {
            color: rgba(0, 0, 0, 1);

            &:before {
                opacity: 1;
                transition: .5s;
            }
        }
    }
}

.loader {
    position: fixed;
    z-index: 20;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    color: #000;
    display: flex;


    &__content {
        display: inline-block;
        margin: auto;
        overflow: hidden;
        transform-origin: 100% 50%;
    }

    &__banner {
        background-color: #fff;
        display: inline-block;
        transform: scaleX(0);
        transform-origin: 0% 50%;
        transition: .5s;
    }

    &__text {
        text-transform: uppercase;
        font-size: 10em;
        padding: 25px 50px;
        margin: 0;
        font-family: 'Roboto', sans-serif;
    }

    &__cletter {
        display: inline-flex;
        margin-right: -35px;
        overflow: hidden;

        &:last-child {
            margin: 0;
        }
    }

    &__letter {
        transform: translate3d(-100%, 0, 0);
    }
}

.count {
    position: absolute;
    top: 0;
    right: 10%;
    margin-top: 10%;

    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;

    &__drag {
        position: fixed;
        bottom: 10%;
        right: 8%;
        margin: 0;
        transform: rotate(90deg);
        transform-origin: center center;
        font-size: .8em;
    }

    &__arrow {
        display: none;
    }

    &__chiffre {
        margin: 0;
        transform: rotate(90deg);
        transform-origin: center center;
    }
}

.box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    cursor: grab;
    margin: 0 auto;
    opacity: 0;
    pointer-events: none;
    display: block;
    transform: scaleY(0);

    &--wishlist {
        img {
            width: 25%;

            &:first-child {
                width: 60%;
            }
        }
    }
}

.loaders {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;

    pointer-events: none;

    &--black {
        width: 100%;
        height: 100%;
        background-color: #000;

        position: absolute;
        top: 0;
        left: 0;

        transform-origin: 0% 50%;
        transform: scaleX(0);
    }

    &--white {
        width: 100%;
        height: 100%;
        background-color: #fff;

        position: absolute;
        top: 0;
        left: 0;

        transform-origin: 0% 50%;
        transform: scaleX(0);
    }

    &--grey {
        width: 100%;
        height: 100%;
        background-color: #f2f2f2;

        position: absolute;
        top: 0;
        left: 0;

        transform-origin: 0% 50%;
        transform: scaleX(0);
    }
}

.projects {
    height: 100vh;
    overflow-y: hidden;
    width: 100%;

    &__el {
        height: 60vh;
        width: 70%;
        margin: 0 auto;
        position: relative;
        opacity: 0;
        pointer-events: none;

        transition: .5s .5s;

        &--actif {
            opacity: 1;
            pointer-events: all;

            .projects__text {
                opacity: 1;
            }
        }

        &--1 {
            margin-top: 20vh;
        }

        &--5 {
            margin-bottom: 20vh;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);

        animation: polygonsReturn 2s normal forwards;

        &--1 {
            background: url('../assets/images/tfa.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--2 {
            background: url('../assets/images/women.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--3 {
            background: url('../assets/images/rethinking2.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--4 {
            background: url('../assets/images/dataplay.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--5 {
            background: url('../assets/images/movie5.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    &__text {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        transition: .5s;
    }

    &__title {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 12vw;
        font-weight: 900;
        margin: 0;
        transform: translate(-50%, -50%);
        transition: .5s;
        -webkit-text-stroke-color: #000;
        -webkit-text-stroke-width: 4px;
        color: black;

        cursor: pointer;
    }

    &__change {
        position: absolute;
        bottom: 15%;
        right: 50px;
        padding-left: 10px;
        width: 7ch;
        height: 1.5em;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -200%;
            width: 200%;
            height: 2px;
            transform-origin: 100% 50%;
            background-color: black;
            transition: .5s;
        }
    }

    &__overflow {
        width: 9ch;
        height: 1.5em;
        position: relative;
        overflow: hidden;
    }

    &__info {
        position: absolute;
        top: 50px;
        left: -20px;
        transform: rotate(90deg);
        transform-origin: left top;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1.5em;
    }
}

@keyframes polygons {
    0% {
        clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
    }

    50% {
        clip-path: polygon(0% 25%, 50% 25%, 50% 75%, 0% 75%);
    }

    100% {
        clip-path: polygon(0% 25%, 100% 25%, 100% 75%, 0% 75%);
    }
}

@keyframes polygonsReturn {
    0% {
        clip-path: polygon(0% 25%, 100% 25%, 100% 75%, 0% 75%);
    }

    50% {
        clip-path: polygon(0% 25%, 50% 25%, 50% 75%, 0% 75%);
    }

    100% {
        clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
    }
}

.link {
    z-index: 10;
}

.tx {
    position: absolute;
    top: 0;
    left: 10px;
    transition: .5s;

    &__second {
        font-family: 'Roboto', sans-serif;
        transform: translate3d(0px, 100%, 0px);
    }

    &__info {
        position: absolute;
        top: 0;
        left: 0;
        transition: .8s;
    }
}

.info {
    padding: 10% 10% 0 10%;

    font-family: 'Roboto', sans-serif;

    &__overflow {
        overflow: hidden;
        display: inline-flex;

        span {
            transform: translateY(-100%);
        }
    }

    h3 {
        font-size: 2em;
    }

    &__box {
        overflow: hidden;
        height: 1.5em;
        width: 15vw;
        position: relative;
    }

    &__picture {
        position: fixed;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        width: 50vmin;

        img {
            width: inherit;
        }
    }

    &__content {
        position: fixed;
        right: 10%;
        top: 0;

        min-height: 100%;
        width: 40%;
        margin-bottom: 10%;

        overflow: scroll;
    }

    &__citation {
        font-family: 'Merriweather', serfi;
        font-size: 3em;
        margin-top: 30%;
        position: relative;
    }

    &__author {
        font-size: .5em;
        font-style: italic;
        margin-left: 65%;
    }

    &__presentation {
        margin-top: 6em;

        h2 {
            font-size: 3em;
        }

        p {
            margin-bottom: 1.5em;
            font-size: 1.2em;
        }
    }

    &__el {
        overflow: hidden;
        display: inline-block;
        float: left;
        clear: both;
    }

    &__social {
        display: inline-block;
        margin-bottom: 30%;
    }

    &__link {
        position: relative;
        padding-bottom: 2px;
        font-size: 1.2em;

        &:before {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #fff;
        }

        &:after {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #fff;
            transform: translateX(-100%);
        }

        &:hover {
            &:before {
                transform: translateX(100%);
                transition: .25s;
            }

            &:after {
                transform: translateX(0%);
                transition: .25s .05s;
            }
        }
    }
}

.project {
    min-height: 100vh;
    width: 100vw;
    background-color: #f2f2f2;

    &__head {
        width: 100vw;
        height: 100vh;
        position: relative;
        font-family: 'Roboto', sans-serif;
        overflow-x: hidden;

    }

    &__count {
        position: absolute;
        top: 50px;
        left: 53vw;
        font-size: .8em;
        display: flex;
        flex-direction: column;
    }

    &__title {
        position: absolute;
        font-family: 'Merriweather', serif;
        font-size: 20vw;
        margin: 0;
        left: 30vw;
        top: 20vh;
        white-space: nowrap;
    }

    &__cletter {
        display: inline-flex;
        margin-right: -4vw;
        overflow: hidden;

        &:last-child {
            margin: 0;
        }
    }

    &__letter {
        transform: translate3d(110%, 0, 0);
        transition: .5s;
    }

    &__arrow {
        width: 1.66667vw;
        height: 1.5em;
        transform: translateX(100%);

        margin-left: 10px;
        transition: .5s;

        svg {
            width: 1.66667vw;
            height: 0.91667vw;
        }
    }

    &__links {
        position: absolute;
        left: 53vw;
        bottom: 20vh;
        font-size: 1.5em;
        display: inline-block;
        transition: .5s;

        &:hover {
            transform: translateX(10px);
            transition: .5s;
        }
    }

    &__overflow {
        overflow: hidden;
        display: inline-flex;

        position: relative;

        span {
            transform: translateY(-100%);
            transition: .5s;
        }
    }

    &__link {
        transform: translateY(100%);
        transition: .5s;
    }

    &__infos {
        position: absolute;
        left: 53vw;
        bottom: 10vh;

        display: flex;
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-right: 10vw;
    }

    &__images {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-top: 25vh;
            width: 60%;
            height: auto;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);

            &:last-child {
                margin-bottom: 100px;
            }
        }
    }

    small {
        transform: translateY(100%);
        transition: .5s;
    }
}

body.inactive .projects__el:hover {
    .projects {
        &__title {
            color: transparent;
            transition: .5s;
        }

        &__change {
            &:after {
                transform: scaleX(.7);
                transition: .3s;
            }
        }
    }

    .tx {
        &__first {
            transform: translate3d(0px, -100%, 0px);
            transition: .3s;
        }

        &__second {
            transform: translate3d(0px, 0px, 0px);
            transition: .3s;
        }
    }
}

body.loading .loader {
    &__banner {
        transform: scaleX(1);
        transition: all 1s 2s;
    }

    &__letter {
        transform: translate3d(0, 0, 0);

        &--1 {
            transition: .5s 3s;
        }

        &--2 {
            transition: .5s 3.05s;
        }

        &--3 {
            transition: .5s 3.1s;
        }

        &--4 {
            transition: .5s 3.15s;
        }

        &--5 {
            transition: .5s 3.2s;
        }

        &--6 {
            transition: .5s 3.25s;
        }

        &--7 {
            transition: .5s 3.3s;
        }
    }
}

body.loaded .loader {
    opacity: 0;
    transition: opacity 1.5s 2s, transform 0s 3.5s;
    pointer-events: none;
    transform: scale(0);


    &__content {
        transform: scaleX(0);
        transition: 1s 1s;
    }

    &__letter {
        opacity: 0;
        transition: .5s .5s;
    }
}

body.active .projects {
    cursor: grabbing;

    &__img {
        animation: polygons 1s normal forwards;
    }

    &__el {
        opacity: 1;
        transition: .5s;
    }
}

body.active--link .projects {
    &__title {
        opacity: 0;
        transform: translate(-25%, -50%);
        transition: opacity .3s, transform .5s;
    }

    &__change {
        &:after {
            transform: scaleX(0);
            transition: .5s;
        }
    }
}

body.active--link .tx {
    &__first {
        transform: translate3d(0px, -100%, 0px);
        transition: .5s;
    }

    &__info {
        transform: translate3d(0, 100%, 0);
        transition: .8s;
    }
}

@media only screen and (max-width: 1049px) {
    .projects {
        &__el {
            position: absolute;
            top: 0;
            left: 5%;
            margin-top: 20%;
            opacity: 0;
            transition: all .5s;
            width: 90%;

            &--actif {
                opacity: 1;
                transition: all .5s .3s;
            }
        }

        &__info {
            top: auto;
            bottom: -100px;
            left: 0px;
            transform: rotate(0deg);
        }
    }

    .count {
        bottom: 40px;
        height: 40px;
        left: 50%;
        transform: translateX(-50%);
        top: auto;
        right: auto;
        width: 100vw;

        &__drag {
            display: none;
        }

        &__arrow {
            display: block;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            height: 22px;
            width: 40px;

            &--left {
                transform-origin: center center;
                transform: rotate(180deg);
                left: 40px;

                svg {
                    margin-top: 10px;
                }
            }

            &--right {
                right: 40px;
            }
        }

        &__chiffre {
            transform: rotate(0deg);
            position: absolute;
            left: 50%;
            top: 50%;

            transform: translate(-50%, -50%);
        }

    }

    .nav {
        margin: 0;
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f2f2f2;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 13;

        &__el {
            top: auto;
            left: auto;
            right: auto;

            &--work {}

            &--info {}

            &:hover {
                &:before {
                    opacity: 0;
                }
            }

            position: relative;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;


        &__box {
            width: 30vw;
        }

        &__picture {
            display: none;
        }

        &__citation {
            margin-top: 0;
        }

        &__content {
            position: relative;
            right: auto;
            top: auto;

            min-height: 100%;
            width: 70ch;
            margin-bottom: 20px;
            margin-top: 30px;
        }

        &__social {
            margin-bottom: 0;
        }

        &__presentation {
            margin-top: 30px;
        }
    }

    body[data-section="info"] {
        overflow: auto;

        .box--info {
            overflow: auto;
        }

        .nav {
            background-color: #000;
        }
    }

    body[data-section="work"] {
        .nav {
            background-color: #fff;
        }
    }

    .loader {
        &__text {
            font-size: 14vw;
        }

        &__cletter {
            margin-right: -2vw;
        }
    }

    .project__arrow {
        width: 3vw;

        svg {
            height: auto;
            width: auto;
        }
    }

}

@media only screen and (max-width: 800px) {

    .loader {
        &__text {
            font-size: 12vw;
        }

        &__cletter {
            margin-right: -2vw;
        }
    }

    .info {
        &__content {
            width: 80vw;
        }

        &__box {
            font-size: .8em;
        }
    }

    .projects {
        &__info {
            bottom: -70px;
        }

        &__title {
            font-size: 16vw;
            letter-spacing: 5px;
            top: auto;
            transform: translate(-50%, 0%);
            bottom: 0;
        }

        &__change {
            bottom: -50px;
            right: 0px;
            width: 3ch;
        }

        &__overflow {
            width: 3ch;
        }

        &__img {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            animation: none;

            &:before {
                content: '';
                height: 100%;
                width: 100%;
                z-index: 2;
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(255, 255, 255, .3);
            }
        }
    }

    .project {
        &__arrow {
            width: 5vw;

            svg {
                height: auto;
                width: auto;
            }
        }

        &__title {
            top: 35%;
        }

        &__images {
            img {
                width: 90%;
                margin-top: 50px;
            }
        }
    }
}

@media (min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .projects__img {
        &--1 {
            background: url('../assets/images/tfa@2x.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--2 {
            background: url('../assets/images/women@2x.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--3 {
            background: url('../assets/images/rethinking2@2x.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--4 {
            background: url('../assets/images/dataplay@2x.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &--5 {
            background: url('../assets/images/movie5@2x.jpg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}
