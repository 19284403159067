body {
  margin: 0 auto;
  padding: 0;
  line-height: 1.6;
  font-size: 18px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  font-family: 'Merriweather', serif;
}

body[data-section="info"] {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

body[data-section="info"] .box--info {
  cursor: initial;
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
  overflow: hidden;
}

body[data-section="info"] .info__picture {
  opacity: 1;
  transition: 1s;
}

body[data-section="info"] .info__content {
  height: 100%;
}

body[data-section="info"] .info__overflow span {
  transform: translateY(0);
  transition: 1s;
}

body[data-section="info"] .nav__el {
  color: rgba(255, 255, 255, 0.5);
}

body[data-section="info"] .nav__el--actif {
  color: white;
}

body[data-section="info"] .nav__el:before {
  border-color: #fff;
}

body[data-section="info"] .nav__el:hover {
  color: white;
}

body[data-section="work"] .box--work {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

body[data-section="contact"] .box--contact {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

body[data-section="tfa"] {
  overflow: auto;
}

body[data-section="tfa"] .box--tfa {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

body[data-section="tfa"] .project__overflow span {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="tfa"] .project__link {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="tfa"] .project__arrow {
  transform: translateX(0);
  transition: .5s .5s;
}

body[data-section="tfa"] .project small {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="tfa"] .project__letter--1 {
  transform: translateX(0);
  transition: .5s;
}

body[data-section="tfa"] .project__letter--2 {
  transform: translateX(0);
  transition: .5s .05s;
}

body[data-section="tfa"] .project__letter--3 {
  transform: translateX(0);
  transition: .5s .1s;
}

body[data-section="tfa"] .project__letter--4 {
  transform: translateX(0);
  transition: .5s .15s;
}

body[data-section="tfa"] .project__letter--5 {
  transform: translateX(0);
  transition: .5s .2s;
}

body[data-section="tfa"] .project__letter--6 {
  transform: translateX(0);
  transition: .5s .25s;
}

body[data-section="tfa"] .project__letter--7 {
  transform: translateX(0);
  transition: .5s .3s;
}

body[data-section="tfa"] .project__letter--8 {
  transform: translateX(0);
  transition: .5s .35s;
}

body[data-section="tfa"] .project__letter--9 {
  transform: translateX(0);
  transition: .5s .4s;
}

body[data-section="dataplay"] {
  overflow: auto;
}

body[data-section="dataplay"] .box--dataplay {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

body[data-section="dataplay"] .project__overflow span {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="dataplay"] .project__link {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="dataplay"] .project__arrow {
  transform: translateX(0);
  transition: .5s .5s;
}

body[data-section="dataplay"] .project small {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="dataplay"] .project__letter--1 {
  transform: translateX(0);
  transition: .5s;
}

body[data-section="dataplay"] .project__letter--2 {
  transform: translateX(0);
  transition: .5s .05s;
}

body[data-section="dataplay"] .project__letter--3 {
  transform: translateX(0);
  transition: .5s .1s;
}

body[data-section="dataplay"] .project__letter--4 {
  transform: translateX(0);
  transition: .5s .15s;
}

body[data-section="dataplay"] .project__letter--5 {
  transform: translateX(0);
  transition: .5s .2s;
}

body[data-section="rux"] {
  overflow: auto;
}

body[data-section="rux"] .box--rux {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

body[data-section="rux"] .project__overflow span {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="rux"] .project__link {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="rux"] .project__arrow {
  transform: translateX(0);
  transition: .5s .5s;
}

body[data-section="rux"] .project small {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="rux"] .project__letter--1 {
  transform: translateX(0);
  transition: .5s;
}

body[data-section="rux"] .project__letter--2 {
  transform: translateX(0);
  transition: .5s .05s;
}

body[data-section="rux"] .project__letter--3 {
  transform: translateX(0);
  transition: .5s .1s;
}

body[data-section="rux"] .project__letter--4 {
  transform: translateX(0);
  transition: .5s .15s;
}

body[data-section="rux"] .project__letter--5 {
  transform: translateX(0);
  transition: .5s .2s;
}

body[data-section="rux"] .project__letter--6 {
  transform: translateX(0);
  transition: .5s .25s;
}

body[data-section="rux"] .project__letter--7 {
  transform: translateX(0);
  transition: .5s .3s;
}

body[data-section="rux"] .project__letter--8 {
  transform: translateX(0);
  transition: .5s .35s;
}

body[data-section="rux"] .project__letter--9 {
  transform: translateX(0);
  transition: .5s .4s;
}

body[data-section="iolce"] {
  overflow: auto;
}

body[data-section="iolce"] .box--iolce {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

body[data-section="iolce"] .project__overflow span {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="iolce"] .project__link {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="iolce"] .project__arrow {
  transform: translateX(0);
  transition: .5s .5s;
}

body[data-section="iolce"] .project small {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="iolce"] .project__letter--1 {
  transform: translateX(0);
  transition: .5s;
}

body[data-section="iolce"] .project__letter--2 {
  transform: translateX(0);
  transition: .5s .05s;
}

body[data-section="iolce"] .project__letter--3 {
  transform: translateX(0);
  transition: .5s .1s;
}

body[data-section="iolce"] .project__letter--4 {
  transform: translateX(0);
  transition: .5s .15s;
}

body[data-section="iolce"] .project__letter--5 {
  transform: translateX(0);
  transition: .5s .2s;
}

body[data-section="iolce"] .project__letter--6 {
  transform: translateX(0);
  transition: .5s .25s;
}

body[data-section="wishlist"] {
  overflow: auto;
}

body[data-section="wishlist"] .box--wishlist {
  opacity: 1;
  pointer-events: all;
  transform: scaleY(1);
}

body[data-section="wishlist"] .project__overflow span {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="wishlist"] .project__link {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="wishlist"] .project__arrow {
  transform: translateX(0);
  transition: .5s .5s;
}

body[data-section="wishlist"] .project small {
  transform: translateY(0);
  transition: .5s .5s;
}

body[data-section="wishlist"] .project__letter--1 {
  transform: translateX(0);
  transition: .5s;
}

body[data-section="wishlist"] .project__letter--2 {
  transform: translateX(0);
  transition: .5s .05s;
}

body[data-section="wishlist"] .project__letter--3 {
  transform: translateX(0);
  transition: .5s .1s;
}

body[data-section="wishlist"] .project__letter--4 {
  transform: translateX(0);
  transition: .5s .15s;
}

body[data-section="wishlist"] .project__letter--5 {
  transform: translateX(0);
  transition: .5s .2s;
}

body[data-section="wishlist"] .project__letter--6 {
  transform: translateX(0);
  transition: .5s .25s;
}

body[data-section="wishlist"] .project__letter--7 {
  transform: translateX(0);
  transition: .5s .3s;
}

body[data-section="wishlist"] .project__letter--8 {
  transform: translateX(0);
  transition: .5s .35s;
}

body[data-loading="true"] {
  overflow: hidden;
}

body[data-loaders="black"] .loaders--black {
  transform: scaleX(1);
  opacity: 0;
  transition: transform .5s, opacity 0s 1s;
}

body[data-loaders="grey"] .loaders--grey {
  transform: scaleX(1);
  opacity: 0;
  transition: transform .5s, opacity 0s 1s;
}

body[data-loaders="white"] .loaders--white {
  transform: scaleX(1);
  opacity: 0;
  transition: transform .5s, opacity .5s 1s;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.navigation {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.nav {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Roboto', sans-serif;
  font-size: .8em;
}

.nav__el {
  position: fixed;
  margin: 0;
  z-index: 16;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.nav__el--work {
  top: 40px;
  left: 80px;
}

.nav__el--info {
  top: 40px;
  right: 80px;
}

.nav__el--actif {
  color: black;
}

.nav__el:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid black;
  transition: .5s;
}

.nav__el:hover {
  color: black;
}

.nav__el:hover:before {
  opacity: 1;
  transition: .5s;
}

.loader {
  position: fixed;
  z-index: 20;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  color: #000;
  display: flex;
}

.loader__content {
  display: inline-block;
  margin: auto;
  overflow: hidden;
  transform-origin: 100% 50%;
}

.loader__banner {
  background-color: #fff;
  display: inline-block;
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: .5s;
}

.loader__text {
  text-transform: uppercase;
  font-size: 10em;
  padding: 25px 50px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.loader__cletter {
  display: inline-flex;
  margin-right: -35px;
  overflow: hidden;
}

.loader__cletter:last-child {
  margin: 0;
}

.loader__letter {
  transform: translate3d(-100%, 0, 0);
}

.count {
  position: absolute;
  top: 0;
  right: 10%;
  margin-top: 10%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
}

.count__drag {
  position: fixed;
  bottom: 10%;
  right: 8%;
  margin: 0;
  transform: rotate(90deg);
  transform-origin: center center;
  font-size: .8em;
}

.count__arrow {
  display: none;
}

.count__chiffre {
  margin: 0;
  transform: rotate(90deg);
  transform-origin: center center;
}

.box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: grab;
  margin: 0 auto;
  opacity: 0;
  pointer-events: none;
  display: block;
  transform: scaleY(0);
}

.box--wishlist img {
  width: 25%;
}

.box--wishlist img:first-child {
  width: 60%;
}

.loaders {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  pointer-events: none;
}

.loaders--black {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0% 50%;
  transform: scaleX(0);
}

.loaders--white {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0% 50%;
  transform: scaleX(0);
}

.loaders--grey {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0% 50%;
  transform: scaleX(0);
}

.projects {
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
}

.projects__el {
  height: 60vh;
  width: 70%;
  margin: 0 auto;
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: .5s .5s;
}

.projects__el--actif {
  opacity: 1;
  pointer-events: all;
}

.projects__el--actif .projects__text {
  opacity: 1;
}

.projects__el--1 {
  margin-top: 20vh;
}

.projects__el--5 {
  margin-bottom: 20vh;
}

.projects__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
  animation: polygonsReturn 2s normal forwards;
}

.projects__img--1 {
  background: url("../assets/images/tfa.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.projects__img--2 {
  background: url("../assets/images/women.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.projects__img--3 {
  background: url("../assets/images/rethinking2.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.projects__img--4 {
  background: url("../assets/images/dataplay.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.projects__img--5 {
  background: url("../assets/images/movie5.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.projects__text {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .5s;
}

.projects__title {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12vw;
  font-weight: 900;
  margin: 0;
  transform: translate(-50%, -50%);
  transition: .5s;
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 4px;
  color: black;
  cursor: pointer;
}

.projects__change {
  position: absolute;
  bottom: 15%;
  right: 50px;
  padding-left: 10px;
  width: 7ch;
  height: 1.5em;
}

.projects__change:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -200%;
  width: 200%;
  height: 2px;
  transform-origin: 100% 50%;
  background-color: black;
  transition: .5s;
}

.projects__overflow {
  width: 9ch;
  height: 1.5em;
  position: relative;
  overflow: hidden;
}

.projects__info {
  position: absolute;
  top: 50px;
  left: -20px;
  transform: rotate(90deg);
  transform-origin: left top;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.5em;
}

@keyframes polygons {
  0% {
    clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(0% 25%, 50% 25%, 50% 75%, 0% 75%);
  }
  100% {
    clip-path: polygon(0% 25%, 100% 25%, 100% 75%, 0% 75%);
  }
}

@keyframes polygonsReturn {
  0% {
    clip-path: polygon(0% 25%, 100% 25%, 100% 75%, 0% 75%);
  }
  50% {
    clip-path: polygon(0% 25%, 50% 25%, 50% 75%, 0% 75%);
  }
  100% {
    clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
  }
}

.link {
  z-index: 10;
}

.tx {
  position: absolute;
  top: 0;
  left: 10px;
  transition: .5s;
}

.tx__second {
  font-family: 'Roboto', sans-serif;
  transform: translate3d(0px, 100%, 0px);
}

.tx__info {
  position: absolute;
  top: 0;
  left: 0;
  transition: .8s;
}

.info {
  padding: 10% 10% 0 10%;
  font-family: 'Roboto', sans-serif;
}

.info__overflow {
  overflow: hidden;
  display: inline-flex;
}

.info__overflow span {
  transform: translateY(-100%);
}

.info h3 {
  font-size: 2em;
}

.info__box {
  overflow: hidden;
  height: 1.5em;
  width: 15vw;
  position: relative;
}

.info__picture {
  position: fixed;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  width: 50vmin;
}

.info__picture img {
  width: inherit;
}

.info__content {
  position: fixed;
  right: 10%;
  top: 0;
  min-height: 100%;
  width: 40%;
  margin-bottom: 10%;
  overflow: scroll;
}

.info__citation {
  font-family: 'Merriweather', serfi;
  font-size: 3em;
  margin-top: 30%;
  position: relative;
}

.info__author {
  font-size: .5em;
  font-style: italic;
  margin-left: 65%;
}

.info__presentation {
  margin-top: 6em;
}

.info__presentation h2 {
  font-size: 3em;
}

.info__presentation p {
  margin-bottom: 1.5em;
  font-size: 1.2em;
}

.info__el {
  overflow: hidden;
  display: inline-block;
  float: left;
  clear: both;
}

.info__social {
  display: inline-block;
  margin-bottom: 30%;
}

.info__link {
  position: relative;
  padding-bottom: 2px;
  font-size: 1.2em;
}

.info__link:before {
  content: '';
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.info__link:after {
  content: '';
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform: translateX(-100%);
}

.info__link:hover:before {
  transform: translateX(100%);
  transition: .25s;
}

.info__link:hover:after {
  transform: translateX(0%);
  transition: .25s .05s;
}

.project {
  min-height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
}

.project__head {
  width: 100vw;
  height: 100vh;
  position: relative;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.project__count {
  position: absolute;
  top: 50px;
  left: 53vw;
  font-size: .8em;
  display: flex;
  flex-direction: column;
}

.project__title {
  position: absolute;
  font-family: 'Merriweather', serif;
  font-size: 20vw;
  margin: 0;
  left: 30vw;
  top: 20vh;
  white-space: nowrap;
}

.project__cletter {
  display: inline-flex;
  margin-right: -4vw;
  overflow: hidden;
}

.project__cletter:last-child {
  margin: 0;
}

.project__letter {
  transform: translate3d(110%, 0, 0);
  transition: .5s;
}

.project__arrow {
  width: 1.66667vw;
  height: 1.5em;
  transform: translateX(100%);
  margin-left: 10px;
  transition: .5s;
}

.project__arrow svg {
  width: 1.66667vw;
  height: 0.91667vw;
}

.project__links {
  position: absolute;
  left: 53vw;
  bottom: 20vh;
  font-size: 1.5em;
  display: inline-block;
  transition: .5s;
}

.project__links:hover {
  transform: translateX(10px);
  transition: .5s;
}

.project__overflow {
  overflow: hidden;
  display: inline-flex;
  position: relative;
}

.project__overflow span {
  transform: translateY(-100%);
  transition: .5s;
}

.project__link {
  transform: translateY(100%);
  transition: .5s;
}

.project__infos {
  position: absolute;
  left: 53vw;
  bottom: 10vh;
  display: flex;
}

.project__info {
  display: flex;
  flex-direction: column;
  margin-right: 10vw;
}

.project__images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project__images img {
  margin-top: 25vh;
  width: 60%;
  height: auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.project__images img:last-child {
  margin-bottom: 100px;
}

.project small {
  transform: translateY(100%);
  transition: .5s;
}

body.inactive .projects__el:hover .projects__title {
  color: transparent;
  transition: .5s;
}

body.inactive .projects__el:hover .projects__change:after {
  transform: scaleX(0.7);
  transition: .3s;
}

body.inactive .projects__el:hover .tx__first {
  transform: translate3d(0px, -100%, 0px);
  transition: .3s;
}

body.inactive .projects__el:hover .tx__second {
  transform: translate3d(0px, 0px, 0px);
  transition: .3s;
}

body.loading .loader__banner {
  transform: scaleX(1);
  transition: all 1s 2s;
}

body.loading .loader__letter {
  transform: translate3d(0, 0, 0);
}

body.loading .loader__letter--1 {
  transition: .5s 3s;
}

body.loading .loader__letter--2 {
  transition: .5s 3.05s;
}

body.loading .loader__letter--3 {
  transition: .5s 3.1s;
}

body.loading .loader__letter--4 {
  transition: .5s 3.15s;
}

body.loading .loader__letter--5 {
  transition: .5s 3.2s;
}

body.loading .loader__letter--6 {
  transition: .5s 3.25s;
}

body.loading .loader__letter--7 {
  transition: .5s 3.3s;
}

body.loaded .loader {
  opacity: 0;
  transition: opacity 1.5s 2s, transform 0s 3.5s;
  pointer-events: none;
  transform: scale(0);
}

body.loaded .loader__content {
  transform: scaleX(0);
  transition: 1s 1s;
}

body.loaded .loader__letter {
  opacity: 0;
  transition: .5s .5s;
}

body.active .projects {
  cursor: grabbing;
}

body.active .projects__img {
  animation: polygons 1s normal forwards;
}

body.active .projects__el {
  opacity: 1;
  transition: .5s;
}

body.active--link .projects__title {
  opacity: 0;
  transform: translate(-25%, -50%);
  transition: opacity .3s, transform .5s;
}

body.active--link .projects__change:after {
  transform: scaleX(0);
  transition: .5s;
}

body.active--link .tx__first {
  transform: translate3d(0px, -100%, 0px);
  transition: .5s;
}

body.active--link .tx__info {
  transform: translate3d(0, 100%, 0);
  transition: .8s;
}

@media only screen and (max-width: 1049px) {
  .projects__el {
    position: absolute;
    top: 0;
    left: 5%;
    margin-top: 20%;
    opacity: 0;
    transition: all .5s;
    width: 90%;
  }
  .projects__el--actif {
    opacity: 1;
    transition: all .5s .3s;
  }
  .projects__info {
    top: auto;
    bottom: -100px;
    left: 0px;
    transform: rotate(0deg);
  }
  .count {
    bottom: 40px;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    right: auto;
    width: 100vw;
  }
  .count__drag {
    display: none;
  }
  .count__arrow {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 22px;
    width: 40px;
  }
  .count__arrow--left {
    transform-origin: center center;
    transform: rotate(180deg);
    left: 40px;
  }
  .count__arrow--left svg {
    margin-top: 10px;
  }
  .count__arrow--right {
    right: 40px;
  }
  .count__chiffre {
    transform: rotate(0deg);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .nav {
    margin: 0;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 13;
  }
  .nav__el {
    top: auto;
    left: auto;
    right: auto;
    position: relative;
  }
  .nav__el:hover:before {
    opacity: 0;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .info__box {
    width: 30vw;
  }
  .info__picture {
    display: none;
  }
  .info__citation {
    margin-top: 0;
  }
  .info__content {
    position: relative;
    right: auto;
    top: auto;
    min-height: 100%;
    width: 70ch;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .info__social {
    margin-bottom: 0;
  }
  .info__presentation {
    margin-top: 30px;
  }
  body[data-section="info"] {
    overflow: auto;
  }
  body[data-section="info"] .box--info {
    overflow: auto;
  }
  body[data-section="info"] .nav {
    background-color: #000;
  }
  body[data-section="work"] .nav {
    background-color: #fff;
  }
  .loader__text {
    font-size: 14vw;
  }
  .loader__cletter {
    margin-right: -2vw;
  }
  .project__arrow {
    width: 3vw;
  }
  .project__arrow svg {
    height: auto;
    width: auto;
  }
}

@media only screen and (max-width: 800px) {
  .loader__text {
    font-size: 12vw;
  }
  .loader__cletter {
    margin-right: -2vw;
  }
  .info__content {
    width: 80vw;
  }
  .info__box {
    font-size: .8em;
  }
  .projects__info {
    bottom: -70px;
  }
  .projects__title {
    font-size: 16vw;
    letter-spacing: 5px;
    top: auto;
    transform: translate(-50%, 0%);
    bottom: 0;
  }
  .projects__change {
    bottom: -50px;
    right: 0px;
    width: 3ch;
  }
  .projects__overflow {
    width: 3ch;
  }
  .projects__img {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    animation: none;
  }
  .projects__img:before {
    content: '';
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .project__arrow {
    width: 5vw;
  }
  .project__arrow svg {
    height: auto;
    width: auto;
  }
  .project__title {
    top: 35%;
  }
  .project__images img {
    width: 90%;
    margin-top: 50px;
  }
}

@media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .projects__img--1 {
    background: url("../assets/images/tfa@2x.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .projects__img--2 {
    background: url("../assets/images/women@2x.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .projects__img--3 {
    background: url("../assets/images/rethinking2@2x.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .projects__img--4 {
    background: url("../assets/images/dataplay@2x.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .projects__img--5 {
    background: url("../assets/images/movie5@2x.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
